@tailwind base;
@tailwind components;
@tailwind utilities;


.button {
  display: flex;
  justify-content: center;
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: linear-gradient(135deg, #e0f7fa 0%, #81d4fa 1%, #039be5 100%);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: rgba(193, 244, 246, 0.698) 0px 0px 0px 0px;
  animation: 1.2s cubic-bezier(0.8, 0, 0, 1) 0s infinite normal none running pulse;
  align-items: center;
  border: 0;
}

.button:is(:hover, :focus) {
  transform: scale(1.2);
}

@keyframes pulse {
  100% {
    box-shadow: 0 0 0 45px rgba(193,244,246,0);
  }
}

.loader {
  width: 50px;
  height: 50px;
  display: flex;
  color: #514b82;
  background:
    linear-gradient(currentColor 0 0) right  /51% 100%,
    linear-gradient(currentColor 0 0) bottom /100% 51%;
  background-repeat: no-repeat;
  animation: l17-0 2s infinite linear .25s;
}
.loader::before{
  content:"";
  width: 50%;
  height: 50%;
  background: currentColor;
  transform-origin: 10% 10%;
  animation: l17-1 .5s infinite linear;
}
@keyframes l17-0 {
  0%   ,12.49% {transform: rotate(0deg)}
  12.5%,37.49% {transform: rotate(90deg)}
  37.5%,62.49% {transform: rotate(180deg)}
  62.5%,87.49% {transform: rotate(270deg)}
  87.5%,100%   {transform: rotate(360deg)}
}
@keyframes l17-1 {
  0%      {transform: perspective(80px) rotate3d(-1,1,0, 0)}
  80%,100%{transform: perspective(80px) rotate3d(-1,1,0,-360deg)}
}